import React from "react"
import Layout from '../components/layout'
import styled from "styled-components"
import ContactForm from '../components/contact-form'
import {Helmet} from "react-helmet";
import Img from "gatsby-image/withIEPolyfill"
import { graphql } from 'gatsby'

const Contact = ({ data }) => {

    return (
        <React.Fragment>
            <Layout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Contact Us | Collins Sporthorses</title>
                <meta name="description" content="Contact Collins Sporthorses. We specialize in the training and boarding of young sport horses. We also have quality trained horses for sale." />
            </Helmet>

            <SlideImage>
            <Img
                fluid={data.hero.childImageSharp.fluid}
                objectFit='cover'
                objectPosition='50% 20%'
                alt='horses'
                style={{ width: "100%", height: "100%" }}
            />
            </SlideImage>
            <Wrapper>
                <Content>
                    <h2>Contact Us</h2>
                </Content>
            </Wrapper>


                <Container>
                    <ContactInfo>
                        <Info>
                            <ul>
                                <li><strong>Name: </strong>Melissa Collins</li>
                                <li><strong>Phone: </strong>(260) 437-7946</li>
                                <li><strong>Email: </strong>miskra1651@gmail.com</li>
                                <li><strong>Address: </strong>270 Newsome Rd. Mayfield, KY 42066</li>
                            </ul>
                        </Info>
                        <div className="contact-form">
                            <ContactForm formName="Contact Form" buttonName="Contact Us" />
                        </div>
                    </ContactInfo>


                {/* Check to see if there are any horses for sale
                { horses !== null &&
                    <HorseListings>
                        <H2>Horses for Sale</H2>
                        <FeaturedHorses>
                            {
                                horses.slice(0,3).map(({ data, uid }) => {
                                    const price = new Intl.NumberFormat({ style: 'currency', currency: 'USD' }).format(data.price.text);
                                    const link = `/horses-for-sale/${uid}`
                                    return (
                                        <div className="horse-listing">
                                            <div className="horse-image">
                                                <img className="feature-image" src={data.picture_of_horse.url} alt={data.picture_of_horse.alt} />
                                            </div>
                                            <div className="horse-data">
                                                <H2>{data.horse_name.text}</H2>
                                                <p>${price}</p>
                                                <StyledLink to={link}>View Horse</StyledLink>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </FeaturedHorses>

                    </HorseListings>
                } */}

                </Container>

            </Layout>

        </React.Fragment>
    )
}

export default Contact

const Container = styled.div`
    width: 90%;
    max-width: 1300px;
    margin: 3em auto;
}
`
const ContactInfo = styled.section`
    @media(min-width: 1000px) {
        display: flex;
        .contact-form {
            width: calc(100% * .6 - 15px);
            margin-left: 15px;
        }
    }
}
`
const Info = styled.div`
background-color: #ECF2EA;
padding: 1em;
@media (min-width: 1000px) {
  padding: 3em;
  width: calc(100% * .4 - 15px);
  margin-right: 15px;
}
ul {
    font-family: 'Lora', serif;
    font-size: 1.2em;
    line-height: 1.75em;
  }
}
`

const SlideImage = styled.div`
position: absolute;
width: 100%;
z-index: 0;
height: 300px;
@media (min-width: 800px) {    
    height: 300px;
}
`;

const Content = styled.div`
max-width: 300px;
position: absolute;
text-align: left;
top: 15%;
text-shadow: 2px 0px 10px rgba(0,0,0,0.74);
h2 {
    font-family: 'Playfair Display', serif;
    font-size: 3em;
}
p {
  font-size: 1.5em;
  font-weight: 700; 
}
@media (min-width: 800px) {
    max-width: 475px;
    h2 {
        font-size: 4em;
        margin-bottom: 25px;
    }
}
`;

const Wrapper = styled.div`
color: #fff;
height: 300px;
margin: auto;
max-width: 85%;
position: relative;
width: 85%;
z-index: 1;
a {
    text-decoration: none;
}
@media (min-width: 800px) {
    height: 300px;
    max-width: 1600px;
    width: 85%;
}
`;

export const query = graphql`
query {
  hero: file(relativePath: { eq: "images/contact.JPG" }) {
    childImageSharp {
      fluid(maxWidth: 1500) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`




